import { TramSet } from "./types.ts";

export const API_BASE_URL = import.meta.env.VITE_API_URL;

export async function fetchTramSets(): Promise<TramSet[]> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/tram-sets`);
    if (!response.ok) throw new Error("Nepodařilo se načíst tramvajové soupravy");
    return await response.json();
  } catch (error) {
    throw new Error("Chyba sítě: Nepodařilo se načíst tramvajové soupravy");
  }
}

export async function verifyTramSet(ordering: string): Promise<void> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/tram-sets/${encodeURIComponent(ordering)}/verify`, {
      method: "POST"
    });
    if (!response.ok) throw new Error("Nepodařilo se ověřit tramvajovou soupravu");
  } catch (error) {
    throw new Error(`Chyba sítě: Nepodařilo se ověřit tramvajovou soupravu - ${(error as Error).message}`);
  }
}

export async function updateTramSetOrdering(oldOrdering: string, newOrdering: string): Promise<void> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/tram-sets/${encodeURIComponent(oldOrdering)}/report-mismatch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ newTramSet: newOrdering })
    });
    if (!response.ok) throw new Error("Nepodařilo se nahlásit nesoulad tramvajové soupravy");
  } catch (error) {
    throw new Error(`Chyba sítě: Nepodařilo se aktualizovat pořadí tramvajové soupravy - ${(error as Error).message}`);
  }
}

export async function refreshDatabase(): Promise<void> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/refresh-database`, {
      method: "POST"
    });
    if (!response.ok) throw new Error("Nepodařilo se aktualizovat databázi");
  } catch (error) {
    throw new Error(`Chyba sítě: Nepodařilo se aktualizovat databázi - ${(error as Error).message}`);
  }
}
