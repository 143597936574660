import { useState, useEffect } from "preact/hooks";
import { loginToken } from "src/constants.ts";
import App from "./App";

export default function Login() {
  const [token, setToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    if (storedAuth === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    if (token === loginToken) {
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true");
    } else {
      alert("Neplatný přihlašovací kód, zkus to znovu.");
    }
  };

  if (isAuthenticated) {
    return <App />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-background-black">
      <div className="max-w-md w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-6">Přihlášení</h1>
        <p className="text-gray-700 dark:text-gray-300 mb-4">Zadej přihlašovací kód pro vstup do aplikace.</p>
        <input
          type="text"
          value={token}
          onChange={(e) => setToken((e.target as HTMLInputElement).value)}
          placeholder="Přihlašovací kód"
          className="w-full p-3 text-sm rounded-lg border dark:bg-gray-700 border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:text-white text-black mb-4"
        />
        <button
          onClick={handleLogin}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Submit
        </button>
      </div>
    </div>
  );
}
