import { ExternalLink } from "lucide-react";
import { useEffect } from "preact/hooks";

interface SeznamAutobusuModalProps {
  tramSet: string;
  onClose: () => void;
}

export function SeznamAutobusuModal({ tramSet, onClose }: SeznamAutobusuModalProps) {
  const cars = tramSet.split("+");

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-dialog-gray rounded-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Seznam vozů</h2>
        <div className="space-y-4">
          {cars.map((car) => (
            <a
              key={car}
              href={`https://seznam-autobusu.cz/seznam?evc=${car}&tractionId=1&evn=&iddopravce=71`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full flex items-center justify-between px-4 py-2 bg-gray-200 dark:bg-gray-700 text-black dark:text-white rounded-md hover:dark:bg-gray-500 hover:bg-gray-300 transition-colors">
              <span>Vůz {car}</span>
              <ExternalLink className="w-5 h-5" />
            </a>
          ))}
        </div>
        <div className="mt-6 flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors">
            Zavřít
          </button>
        </div>
      </div>
    </div>
  );
}
