import { invalidVehicles } from "src/constants.ts";
import { TramSet } from "src/types.ts";

export function timeAgoInCzech(date: Date) {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(diffInSeconds / 3600);
  const days = Math.floor(diffInSeconds / 86400);

  if (minutes < 1) {
    return "před méně než minutou";
  } else if (minutes === 1) {
    return "před 1 minutou";
  } else if (minutes < 60) {
    return `před ${minutes} minutami`;
  } else if (hours === 1) {
    return "před 1 hodinou";
  } else if (hours < 24) {
    return `před ${hours} hodinami`;
  } else if (days === 1) {
    return "před 1 dnem";
  }

  return `před ${days} dny`;
}

export function filterInvalidSets(tramSets: TramSet[]) {
  return tramSets.filter((tramSet) => {
    if (!tramSet.ordering.includes("+")) return false;

    return !invalidVehicles.some((x) => tramSet.ordering.includes(x));
  });
}
