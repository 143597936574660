import React from "preact/compat";
import { useEffect, useState } from "preact/hooks";

interface UpdateTramSetModalProps {
  tramSetOrdering: string;
  onUpdate: (newTramSet: string) => void;
  onClose: () => void;
}

export function UpdateTramSetModal({ tramSetOrdering, onUpdate, onClose }: UpdateTramSetModalProps) {
  const currentVehicles = tramSetOrdering.split("+");

  const [newFirstVehicle, setNewFirstVehicle] = useState(currentVehicles[0]);
  const [newSecondVehicle, setNewSecondVehicle] = useState(currentVehicles[1]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newFirstVehicle.trim() && newSecondVehicle.trim()) {
      onUpdate(`${newFirstVehicle}+${newSecondVehicle}`);
    }
  };

  return (
    <div class="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div class="bg-white dark:bg-dialog-gray rounded-lg p-6 w-full max-w-md">
        <h2 class="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Aktualizovat soupravu</h2>
        <form onSubmit={handleSubmit}>
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Přední vůz</label>
            <input
              type="number"
              value={newFirstVehicle}
              pattern="[0-9]*"
              onChange={(e) => setNewFirstVehicle(e.currentTarget.value)}
              placeholder="Zadej číslo vozu"
              class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600  rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white"
              autoFocus
            />
          </div>
          <div class="mb-6">
            <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Zadní vůz</label>
            <input
              type="number"
              value={newSecondVehicle}
              pattern="[0-9]*"
              onChange={(e) => setNewSecondVehicle(e.currentTarget.value)}
              placeholder="Zadej číslo vozu"
              class="w-full px-3 py-2 border border-gray-300 dark:border-gray-600  rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 text-gray-900 dark:text-white"
              autoFocus
            />
          </div>
          <div class="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              class="px-4 py-2 text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors">
              Zrušit
            </button>
            <button
              type="submit"
              class="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!newFirstVehicle.trim() || !newSecondVehicle.trim()}>
              Odeslat
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
