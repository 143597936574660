import { useEffect } from "preact/hooks";

interface ToastProps {
  message: string;
  type: "success" | "error";
  onClose: () => void;
}

export function Toast({ message, type, onClose }: ToastProps) {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div class={`fixed bottom-4 right-4 p-4 rounded-lg text-white flex items-center gap-3 z-50 animate-slide-in ${type === "success" ? "bg-green-500" : "bg-red-500"}`}>
      <span>{message}</span>
      <button onClick={onClose} class="text-white hover:text-gray-200 text-xl leading-none">
        ×
      </button>
    </div>
  );
}
