import { useState, useEffect } from "react";
import { AlertCircle, CheckCircle2, Edit3, Bus, RotateCw } from "lucide-react";
import { Toast } from "src/components/Toast.tsx";
import { UpdateTramSetModal } from "src/components/UpdateTramSetModal.tsx";
import { SeznamAutobusuModal } from "src/components/SeznamAutobusuModal.tsx";
import { fetchTramSets, verifyTramSet, updateTramSetOrdering, refreshDatabase } from "src/api.ts";
import { TramSet } from "src/types.ts";
import { filterInvalidSets, timeAgoInCzech } from "src/helpers.ts";

export default function App() {
  const [tramSets, setTramSets] = useState<TramSet[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [toast, setToast] = useState<{ message: string; type: "success" | "error" } | null>(null);
  const [updateModal, setUpdateModal] = useState<string | null>(null);
  const [seznamModal, setSeznamModal] = useState<string | null>(null);
  const [duplicates, setDuplicates] = useState<Record<string, string[]>>({});
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    loadTramSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadTramSets() {
    try {
      const data = await fetchTramSets();
      const sortedData = data.sort((a, b) => {
        if (a.isDeployed !== b.isDeployed) {
          return a.isDeployed ? -1 : 1;
        }

        if (!a.lastVerifiedAt) return 1;
        if (!b.lastVerifiedAt) return -1;

        return new Date(a.lastVerifiedAt).getTime() - new Date(b.lastVerifiedAt).getTime();
      });

      const filteredData = filterInvalidSets(sortedData);

      setTramSets(filteredData);
      findDuplicates(filteredData);
      setError(null);
    } catch (error) {
      setError("Nepodařilo se načíst tramvajové soupravy. Zkuste to prosím později.");
    } finally {
      setLoading(false);
    }
  }

  function findDuplicates(tramSets: TramSet[]) {
    const vehicleOccurences: Record<string, string[]> = {};

    tramSets.forEach((set) => {
      const vehicles = set.ordering.split("+");
      vehicles.forEach((vehicle) => {
        if (!vehicleOccurences[vehicle]) {
          vehicleOccurences[vehicle] = [];
        }
        vehicleOccurences[vehicle].push(set.ordering);
      });
    });

    const duplicates = Object.fromEntries(Object.entries(vehicleOccurences).filter(([, sets]) => sets.length > 1));

    setDuplicates(duplicates);
  }

  async function handleVerify(ordering: string) {
    try {
      await verifyTramSet(ordering);
      const updatedTramSets = tramSets.map((set) => (set.ordering === ordering ? { ...set, lastVerifiedAt: new Date().toISOString() } : set));
      setTramSets(updatedTramSets);
      setToast({ message: "Tramvajová souprava byla úspěšně ověřena", type: "success" });
    } catch (error) {
      setToast({ message: "Nepodařilo se ověřit tramvajovou soupravu", type: "error" });
    }
  }

  async function handleRefreshClick() {
    try {
      setRefreshing(true);
      await refreshDatabase();
      await loadTramSets();
      setRefreshing(false);
      setToast({ message: "Databáze byla úspěšně aktualizována", type: "success" });
    } catch (error) {
      setToast({ message: "Nepodařilo se aktualizovat databázi", type: "error" });
    } finally {
      setRefreshing(false);
    }
  }

  async function handleUpdate(oldOrdering: string, newTramSet: string) {
    try {
      await updateTramSetOrdering(oldOrdering, newTramSet);
      setUpdateModal(null);
      setToast({ message: "Byl úspěšně odeslán návrh na změny soupravy", type: "success" });
    } catch (error) {
      setToast({ message: "Nepodařilo se aktualizovat tramvajovou soupravu", type: "error" });
    }
  }

  const filteredTramSets = tramSets.filter((tramSet) => tramSet.ordering.toLowerCase().includes(searchQuery.toLowerCase()));

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-background-black flex items-center justify-center">
        <div className="animate-pulse text-lg text-gray-600 dark:text-gray-300">Načítání souprav...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-background-black py-6 px-4 sm:px-6 lg:px-8 relative">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100 mb-8">Tramvajové soupravy</h1>

        <div className="mb-6">
          <p class="text-gray-900 dark:text-gray-200">Tato webová aplikace poskytuje rychlý přehled všech tramvajových souprav v síti Plzeňské MHD.</p>
          <p class="text-gray-900 dark:text-gray-100">
            Databáze souprav se automaticky stahuje ze Seznamu Autobusů každé ráno v <b>7 hodin</b>, jinak lze využít tlačítka pro manuální aktualizaci.
          </p>
          <p class="text-gray-900 dark:text-gray-100 mb-4">Nejsou zobrazovány vozy, které nejsou v soupravě nebo které nejsou provozní (odstavené, v dílnách).</p>

          <ul class="list-disc list-inside text-gray-900 dark:text-gray-200 mb-4">
            <li>
              <span class="text-green-400 font-medium">Zeleně</span> označené soupravy jsou aktuálně nasazené v provozu, je dostupná informace o jejich poloze.
            </li>
            <li>
              <span class="text-red-600 font-medium">Červeně</span> označené soupravy jsou neplatné, jejich vozy jsou obsaženy v minimálně jedné další soupravě.
            </li>
          </ul>

          <p class="text-gray-900 dark:text-gray-100 mb-4">Aplikace nabízí následující ovládací prvky:</p>
          <ul class="list-disc list-inside text-gray-900 dark:text-gray-200 mb-4">
            <li>
              Tlačítko <strong>"Ověřit"</strong> uloží časové razítko kontroly soupravy v terénu.
            </li>
            <li>
              Tlačítko <strong>"Upravit"</strong> umožňuje nahlásit novou podobu soupravy na Discord.
            </li>
            <li>
              Tlačítko <strong>"Seznam Autobusů"</strong> slouží jako rychlý proklik do evidence.
            </li>
          </ul>
        </div>

        <button
          onClick={handleRefreshClick}
          className={`mb-4 inline-flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors
            ${refreshing ? "opacity-50 cursor-not-allowed" : ""}`}>
          <RotateCw className="w-4 h-4 mr-2" />
          {refreshing ? "Aktualizuji..." : "Aktualizovat soupravy ze Seznamu Autobusů"}
        </button>
        {/* Error Alert */}
        {error && (
          <div className="mb-6 p-4 bg-red-50 dark:bg-red-900 border-l-4 border-red-500 rounded-md flex items-center gap-3">
            <AlertCircle className="text-red-500" />
            <p className="text-red-700 dark:text-red-300">{error}</p>
          </div>
        )}

        {/* Tram Sets List */}
        <div className="space-y-4">
          {filteredTramSets.map((tramSet) => {
            const vehicles = tramSet.ordering.split("+");
            const conflictingSets = vehicles
              .map((vehicle) => duplicates[vehicle])
              .flat()
              .filter((set) => set && set !== tramSet.ordering);

            const isDuplicate = conflictingSets.length > 0;

            return (
              <div key={tramSet.ordering} className={`bg-white dark:bg-card-gray rounded-lg shadow-sm transition-all hover:shadow-md ${tramSet.isDeployed ? "border-l-4 border-l-green-500" : ""}`}>
                <div className="p-6 flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                  <div className="flex-1">
                    <h2 className={`text-2xl font-semibold mb-2 ${isDuplicate ? "text-red-500" : "text-gray-900 dark:text-gray-100"}`}>{tramSet.ordering}</h2>
                    {isDuplicate && (
                      <p className="text-gray-600 dark:text-gray-300">
                        Duplicitní soupravy: <span className="text-red-600 dark:text-red-400">{conflictingSets.join(", ")}</span>
                      </p>
                    )}
                    {tramSet.location && (
                      <p className="text-gray-600 dark:text-gray-300">
                        Poslední poloha: <b>{tramSet.location}</b>
                      </p>
                    )}
                    {tramSet.line && (
                      <p className="text-gray-600 dark:text-gray-300">
                        Linka: <b>{tramSet.line}</b>
                      </p>
                    )}
                    {tramSet.lastVerifiedAt && (
                      <p className="text-gray-600 dark:text-gray-300">
                        Naposledy ověřené: <b>{timeAgoInCzech(new Date(tramSet.lastVerifiedAt))}</b>
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col sm:flex-row gap-5">
                    <button
                      onClick={() => handleVerify(tramSet.ordering)}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors">
                      <CheckCircle2 className="w-4 h-4 mr-2" />
                      Ověřit
                    </button>
                    <button
                      onClick={() => setUpdateModal(tramSet.ordering)}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors">
                      <Edit3 className="w-4 h-4 mr-2" />
                      Upravit
                    </button>
                    <button
                      onClick={() => setSeznamModal(tramSet.ordering)}
                      className="inline-flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors">
                      <Bus className="w-4 h-4 mr-2" />
                      Seznam Autobusů
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Modals */}
        {updateModal && <UpdateTramSetModal tramSetOrdering={updateModal} onUpdate={(newSecondVehicle: string) => handleUpdate(updateModal, newSecondVehicle)} onClose={() => setUpdateModal(null)} />}
        {seznamModal && <SeznamAutobusuModal tramSet={seznamModal} onClose={() => setSeznamModal(null)} />}
        {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}

        {/* Mobile Search Bar */}
        <div className="fixed bottom-4 left-4 right-4 md:static md:mt-6">
          <input
            type="number"
            pattern="[0-9]*"
            value={searchQuery}
            onChange={(e) => setSearchQuery((e.target as HTMLInputElement).value)}
            placeholder="Vyhledat soupravu..."
            className="w-full p-3 text-sm rounded-lg border dark:bg-gray-800 border-gray-300 dark:border-gray-700 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:text-white text-black"
          />
        </div>
      </div>
    </div>
  );
}
